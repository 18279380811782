import { PostHogProvider, usePostHog } from "posthog-js/react";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import Modal from "react-modal";
import { ToastContainer } from "react-toastify";
import Footer from "./footer";
import Header from "./header";
import CartDrawer from "./partials/cart-drawer";
import PromoModal from "./partials/promo-modal";

import "react-modern-drawer/dist/index.css";
import "react-toastify/dist/ReactToastify.css";
import "./layout.css";

const Layout = ({ children, location, withoutPosthog = false }) => {
  useEffect(() => {
    Modal.setAppElement("#modalRoot");
  }, []);

  if (withoutPosthog) {
    return <PageContents>{children}</PageContents>;
  }

  return (
    <PostHogProvider
      apiKey={process.env.POSTHOG_API_KEY}
      options={{
        api_host: "/ingest",
        ui_host: "https://us.posthog.com",
      }}
    >
      <PosthogSendPageView location={location}>
        <PageContents>{children}</PageContents>
      </PosthogSendPageView>
    </PostHogProvider>
  );
};

const PageContents = ({ children }) => {
  return (
    <div>
      <Helmet>
        <style type="text/css">{`
              a {
                color: ${process.env.THEME_COLOR_PRIMARY_DARKEST};
              }
              .product-bg-image:before {
                background-color: ${process.env.THEME_COLOR_PRIMARY_DARKER};
              }
              .product-bg-image:before {
                background-color: ${process.env.THEME_COLOR_PRIMARY_DARKER};
              }
              .heroicon-outline {
                fill: ${process.env.THEME_COLOR_PRIMARY_HEX};
              }
              .heroicon-shadow {
                fill: ${process.env.THEME_COLOR_PRIMARY_HEX}; opacity:0.4;
              }
              .heroicon-shadows {
                fill: ${process.env.THEME_COLOR_PRIMARY_DARKER}; opacity:0.4;
              }
              .heroicon-component-fill {
                fill: #FFFFFF;
              }
              .heroicon-component-accent {
                fill: ${process.env.THEME_COLOR_PRIMARY_LIGHTER};
              }
          `}</style>
      </Helmet>
      <Header />
      <CartDrawer />
      <PromoModal />
      <div className="m-auto font-sans main-wrapper" id="modalRoot">
        <ToastContainer hideProgressBar={true} />
        <main>{children}</main>
        <Footer />
      </div>
    </div>
  );
};

const PosthogSendPageView = ({ children, location }) => {
  const posthog = usePostHog();

  useEffect(() => {
    if (typeof window === "undefined") {
      return;
    }

    posthog.capture("$pageview");
  }, [posthog, location.pathname]);

  return <>{children}</>;
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
