const zipCodes = [
  80002,
  80003,
  80004,
  80005,
  80007,
  80010,
  80011,
  80012,
  80013,
  80014,
  80015,
  80016,
  80017,
  80018,
  80019,
  80020,
  80021,
  80022,
  80023,
  80024,
  80026,
  80027,
  80030,
  80031,
  80033,
  80035,
  80036,
  80040,
  80042,
  80045,
  80104,
  80106,
  80107,
  80108,
  80109,
  80110,
  80111,
  80112,
  80113,
  80116,
  80118,
  80120,
  80121,
  80122,
  80123,
  80124,
  80125,
  80126,
  80127,
  80128,
  80129,
  80130,
  80132,
  80133,
  80134,
  80135,
  80138,
  80202,
  80203,
  80204,
  80205,
  80206,
  80207,
  80209,
  80210,
  80211,
  80212,
  80214,
  80215,
  80218,
  80219,
  80220,
  80221,
  80222,
  80223,
  80224,
  80226,
  80227,
  80228,
  80229,
  80230,
  80231,
  80232,
  80233,
  80234,
  80235,
  80236,
  80237,
  80238,
  80239,
  80241,
  80246,
  80247,
  80249,
  80260,
  80301,
  80302,
  80303,
  80304,
  80305,
  80306,
  80307,
  80309,
  80310,
  80314,
  80401,
  80403,
  80465,
  80501,
  80502,
  80503,
  80504,
  80513,
  80514,
  80516,
  80520,
  80530,
  80601,
  80602,
  80603,
  80621,
  80640,
  80903,
  80904,
  80905,
  80906,
  80907,
  80908,
  80909,
  80910,
  80911,
  80913,
  80915,
  80916,
  80917,
  80918,
  80919,
  80920,
  80921,
  80922,
  80923,
  80924,
  80925,
  80927,
  80951,
];

const extendedZipCodes = [
  80521,
  80524,
  80525,
  80526,
  80528,
  80534,
  80537,
  80538,
  80542,
  80543,
  80547,
  80550,
  80620,
  80631,
  80634,
  80642,
  80651,
  80817,
  80818,
  80831,
];

export { zipCodes, extendedZipCodes };
