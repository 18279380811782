import { graphql, useStaticQuery } from "gatsby";
import React, { useState } from "react";
import NavDesktop from "./partials/nav-desktop";
import NavMobile from "./partials/nav-mobile";
import PromoBanner from "./partials/promo-banner";

const Header = () => {
  const [showMenu, setShowMenu] = useState(false);

  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  return (
    <header>
      <PromoBanner />
      <NavMobile
        setShowMenu={setShowMenu}
        showMenu={showMenu}
        siteTitle={data.site.siteMetadata.title}
      />
      <NavDesktop siteTitle={data.site.siteMetadata.title} />
    </header>
  );
};

export default Header;
