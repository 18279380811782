import classNames from "classnames";
import { Link } from "gatsby";
import React, { Fragment, useContext } from "react";
import CartContext from "../../context/cart-context";
import { navItems, productNavItems } from "../../data/nav-items";
import { HeaderImage } from "../images/header-image";

const componentStyles = {
  Mountainland: {
    container: `bg-${process.env.THEME_COLOR_PRIMARY} text-black`,
    link: "text-black",
    fillColor: "black",
    phoneNumberColor: "text-black",
  },
  Wasatch: {
    container: `bg-${process.env.THEME_COLOR_PRIMARY}-darkest text-white`,
    link: "text-black",
    fillColor: "white",
    phoneNumberColor: "text-white",
  },
};

const NavMobile = ({ showMenu, setShowMenu, siteTitle }) => {
  const cart = useContext(CartContext);
  return (
    <div
      className={classNames(
        "block md:hidden",
        componentStyles[process.env.COMPANY_NAME_SHORTER].container
      )}
    >
      <div className="flex justify-between">
        <div className="flex items-center py-3 pl-4">
          <Link to="/" title={siteTitle}>
            <HeaderImage />
          </Link>
        </div>
        <div className="flex flex-row-reverse items-center justify-end py-2 px-4 gap-x-6">
          <div className="flex items-center justify-end gap-4">
            <Link
              to="/cart/"
              className="relative block pr-3 py-1 mr-1"
              style={{ fill: `${process.env.THEME_COLOR_PRIMARY}` }}
              title="Cart"
            >
              <span
                className={`${
                  cart.totals.countItems
                    ? "header-cart-icon text-center"
                    : "hidden"
                }`}
              >
                {cart.totals.countItems || ""}
              </span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="25"
                height="25"
                viewBox="0 0 100 100"
                className="heroicon-cart heroicon heroicons-lg"
              >
                <path
                  fill={
                    componentStyles[process.env.COMPANY_NAME_SHORTER].fillColor
                  }
                  d="M10 11V9h3.88a2 2 0 0 1 1.94 1.51L27.56 57.5l.38 1.51h59l-.5 2H23a8 8 0 1 0 0 16h62a1 1 0 0 1 0 2H23a10 10 0 0 1-10-10c0-5.18 5.04-9.77 10.58-10l2.46-.1-.6-2.37-10.9-43.64A2.5 2.5 0 0 0 12.1 11H10zm20 70h9.17l-8 8H30v-8zm42 0h9.17l-8 8H72v-8z"
                />
                <path
                  fill={
                    componentStyles[process.env.COMPANY_NAME_SHORTER].fillColor
                  }
                  d="M87.44 57H29.56l-8.75-35h3.94L27 31h6v-9h1v9h9v-9h1v9h9v-9h1v9h9v-9h1v9h9v-9h1v9h9v-9h1v9h6l2.25-9h3.94l-.13.51L87.44 57zm1.28-25H84v10h3.25l2.25-9 .25-1h-1.03zM29.75 42H33V32H27.25l.25 1 2.25 9zm56.22 1H84v10h.5l.5-2 1.75-7 .25-1h-1.03zM30 43l.25 1L32 51l.5 2h.5V43h-3zm42-11h-8v10h9V32h-1zm0 11h-8v10h9V43h-1zm-9-2v-9h-9v10h9v-1zm-8 2h-1v10h9V43h-8zm-2-2v-9h-9v10h9v-1zm-8 2h-1v10h9V43h-8zm-2-2v-9h-9v10h9v-1zm-8 2h-1v10h9V43h-8zm40 0h-1v10h9V43h-8zm7-1h1V32h-9v10h8zm16-21H20.55l-1-4H98v4z"
                />
                <path
                  fill={
                    componentStyles[process.env.COMPANY_NAME_SHORTER].fillColor
                  }
                  d="M4 8h4v4H4a2 2 0 1 1 0-4z"
                />
              </svg>
            </Link>
            <button
              aria-label="Menu"
              className="flex items-center uppercase no-underline"
              onClick={() => setShowMenu(!showMenu)}
              onKeyDown={() => setShowMenu(!showMenu)}
            >
              <svg
                className="h-6 w-6 fill-current"
                viewBox="0 0 20 20"
                xmlns="https://www.w3.org/2000/svg"
              >
                <title>Menu</title>
                <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
              </svg>
            </button>
          </div>
          <a
            href={`tel:${process.env.PHONE_NUMBER}`}
            className={classNames(
              "whitespace-nowrap",
              componentStyles[process.env.COMPANY_NAME_SHORTER].phoneNumberColor
            )}
          >
            {process.env.PHONE_NUMBER}
          </a>
        </div>
      </div>

      <div
        className={`bg-grey-lighter px-6 justify-end hidden flex-grow tracking-wide shadow-lg ${
          showMenu ? "mobile-menu-is-active" : ""
        }`}
        onClick={() => setShowMenu(!showMenu)}
        onKeyDown={() => setShowMenu(!showMenu)}
        role="button"
        tabIndex="0"
      >
        <Link
          to="/"
          className="block py-4 uppercase font-bold border-t border-grey-light text-grey-darkest hover:text-black no-underline"
        >
          Home
        </Link>
        {productNavItems.map((item, index) => (
          <Fragment key={index}>
            <Link
              to={item.link}
              className="block py-4 uppercase font-bold border-t border-grey-light text-grey-darkest hover:text-black no-underline"
            >
              {item.title}
            </Link>
            {item.subNav &&
              item.subNav.map((subItem, subItemIndex) => (
                <Link
                  className="block py-4 pl-6 uppercase text-sm border-t border-grey-light text-grey-darkest hover:text-black no-underline"
                  key={subItemIndex}
                  to={subItem.link}
                >
                  {subItem.title}
                </Link>
              ))}
          </Fragment>
        ))}
        {navItems.map((item, index) => (
          <Fragment key={index}>
            <Link
              to={item.link}
              className="block py-4 uppercase font-bold border-t border-grey-light text-grey-darkest hover:text-grey-lightest no-underline"
            >
              {item.title}
            </Link>
            {item.subNav &&
              item.subNav.map((subItem, subItemIndex) => (
                <Link
                  className="block py-4 pl-6 uppercase text-sm border-t border-grey-light text-grey-darkest hover:text-grey-lightest no-underline"
                  key={subItemIndex}
                  to={subItem.link}
                >
                  {subItem.title}
                </Link>
              ))}
          </Fragment>
        ))}
      </div>
    </div>
  );
};
export default NavMobile;
