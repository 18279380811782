import { useLocation } from "@reach/router";
import axios from "axios";
import classNames from "classnames";
import { parse } from "query-string";
import React, { useContext, useEffect, useState } from "react";
import Modal from "react-modal";
import CartContext from "../../context/cart-context";
import scrollPositionHook from "../../hooks/scrollPositionHook";
import PopupImage from "../../images/installation-trimmed.jpg";
import { styles } from "../../utilities/styles";
import Logo from "../images/logo";

const PromoModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [subTitle, setSubtitle] = useState("");
  const [body, setBody] = useState("");
  const [submitButtonText, setSubmitButtonText] = useState("");
  const [queryString, setQueryString] = useState("");
  const [campaignId, setCampaignId] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const [autoApplyCouponCode, setAutoApplyCouponCode] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [afterSubmitBody, setAfterSubmitBody] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const location = useLocation();
  const search = parse(location.search);
  const [hasAlreadyOpened, setHasAlreadyOpened] = useState(false);
  const scrollPositionResult = scrollPositionHook();
  const [popupDesign, setPopupDesign] = useState("");
  const cartContext = useContext(CartContext);

  function closeModal() {
    setIsOpen(false);
  }
  useEffect(() => {
    async function checkForMarketingInterstitial() {
      const search = location.search ? location.search.substring(1) : "";
      setQueryString(search);
      const response = await axios.get(
        `${process.env.DATA_API}/wp-json/${
          process.env.COMPANY_NAME_SLUG
        }/v1/marketingInterstitials/?query_string=${encodeURIComponent(search)}`
      );

      if (response.status === 200) {
        if (response.data.length) {
          if (response.data[0].trigger === "immediately") {
            setIsOpen(true);
            setHasAlreadyOpened(true);
          }
          setPopupDesign(response.data[0].design);
          setTitle(response.data[0].title);
          setSubtitle(response.data[0].subtitle);
          setBody(response.data[0].body);
          setSubmitButtonText(response.data[0].submit_button_text);
          setCampaignId(response.data[0].campaign_id);
          setCouponCode(response.data[0].coupon_code);
          setAfterSubmitBody(response.data[0].after_submit_body);
          setAutoApplyCouponCode(response.data[0].auto_apply_coupon_code);
        }
      } else {
        console.error(response);
      }
    }
    checkForMarketingInterstitial();
  }, [location.search]);

  useEffect(() => {
    if (
      scrollPositionResult >= window.innerHeight / 2 &&
      !hasAlreadyOpened &&
      title
    ) {
      setIsOpen(true);
      setHasAlreadyOpened(true);

      sessionStorage.setItem(`modal.${campaignId}.${title}`, true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAlreadyOpened, scrollPositionResult]);

  const submitForm = async () => {
    const response = await axios.post(
      "/.netlify/functions/generic-conversion",
      {
        data: {
          event: campaignId,
          customer_properties: {
            $email: email,
            $first_name: name,
          },
          properties: {
            queryString,
            campaignId,
            couponCode,
            email,
            name,
            utm_source: search.utm_source,
          },
        },
      }
    );

    if (response.status === 200) {
      if (autoApplyCouponCode) {
        cartContext.setDiscountCode(couponCode);
      }
      setIsSubmitted(true);
    } else {
      console.error(response);
    }
  };
  let popupOrientation;
  if (popupDesign === "backgroundImage") {
    popupOrientation = (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Promo Modal"
        className="absolute top-0 right-0 bottom-0 left-0 m-4 lg:mx-auto lg:my-24 lg:max-w-xl xl:max-w-2xl bg-white shadow-lg rounded-lg bpd-promo-modal-bg border-4 border-white"
        overlayClassName={`z-50 fixed top-0 right-0 bottom-0 left-0`}
        style={{
          overlay: {
            backgroundColor: `${process.env.THEME_COLOR_BG_TRANSPARENT_50}`,
          },
          content: {},
        }}
      >
        <div className="h-full flex flex-col justify-between">
          <div className="py-8 lg:pt-24 lg:py-16 px-12 flex flex-col lg:justify-center lg:h-full overflow-y-scroll">
            <h2 className={`text-5xl text-medium`}>{title}</h2>
            <h3 className={`text-3xl mt-2 font-bold`}>{subTitle}</h3>
            <button
              className="absolute right-0 top-0 mr-2 mt-2 p-4 w-8 h-8 bg-white rounded-full flex items-center justify-center"
              onClick={closeModal}
            >
              x
            </button>
            <div
              className={`py-8 leading-normal max-w-lg text-lg`}
              dangerouslySetInnerHTML={{ __html: body }}
            />
          </div>
          <div className="bg-white py-4 px-12 h-full">
            {!isSubmitted ? (
              <form
                className="flex flex-wrap w-full text-left items-end"
                method="POST"
                onSubmit={(e) => {
                  e.preventDefault();
                  submitForm();
                }}
              >
                <div className="w-full lg:w-1/3">
                  <div className="py-1 lg:p-4">
                    <label className="block">
                      Name*
                      <br />
                      <input
                        className="border border-grey-light py-3 px-4 w-full mt-2 text-base"
                        name="name"
                        placeholder=""
                        type="text"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div className="w-full lg:w-1/3">
                  <div className="py-1 lg:p-4">
                    <label className="block">
                      Email*
                      <br />
                      <input
                        className="border border-grey-light py-3 px-4 w-full mt-2 text-base"
                        name="email"
                        placeholder=""
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </label>
                  </div>
                </div>
                <div className="w-full lg:w-1/3">
                  <div className="py-1 lg:p-4 text-center">
                    <button
                      className={classNames(
                        "w-full block tracking-wide text-md px-8 py-4 no-underline rounded-sm shadow uppercase mt-4",
                        styles.button.default
                      )}
                      type="submit"
                    >
                      {submitButtonText}
                    </button>
                  </div>
                </div>
              </form>
            ) : (
              <div
                className="bg-gray-dark h-full flex items-center"
                style={{ minHeight: "80px" }}
              >
                <div dangerouslySetInnerHTML={{ __html: afterSubmitBody }} />
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  } else {
    popupOrientation = (
      <Modal
        isOpen={isOpen}
        onRequestClose={closeModal}
        contentLabel="Promo Modal"
        className="absolute top-0 right-0 bottom-0 left-0 m-4 mx-auto my-auto max-h-lg max-w-sm  bg-white shadow-lg rounded-lg"
        overlayClassName={`z-50 fixed top-0 right-0 bottom-0 left-0`}
        style={{
          overlay: {
            backgroundColor: `${process.env.THEME_COLOR_BG_TRANSPARENT_50}`,
          },
        }}
        shouldCloseOnOverlayClick={true}
      >
        <div className="h-full flex flex-col justify-between">
          <div className="bg-white h-full">
            {!isSubmitted ? (
              <div>
                <img
                  className="object-cover object-bottom h-3/6 w-auto"
                  src={PopupImage}
                  alt="Installation"
                />
                <h3 className={`text-2xl mt-2 font-bold px-6 text-center`}>
                  {title}
                </h3>
                <form
                  className="flex flex-col w-full text-left items-end pb-4 px-6"
                  method="POST"
                  onSubmit={(e) => {
                    e.preventDefault();
                    submitForm();
                  }}
                >
                  <div className="w-full ">
                    <div className="py-2">
                      <input
                        className={`border-2 border-${process.env.THEME_COLOR_PRIMARY} text-center py-3 px-4 w-full mt-2 text-base`}
                        name="name"
                        placeholder="Enter Your Name"
                        type="text"
                        required
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-full ">
                    <div className="py-2">
                      <input
                        className={`border-2 border-${process.env.THEME_COLOR_PRIMARY} text-center py-3 px-4 w-full mt-2 text-base`}
                        name="email"
                        placeholder="Enter Your Email Address"
                        type="email"
                        required
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="w-full ">
                    <div className="py-2 text-center">
                      <button
                        className={classNames(
                          "w-full block tracking-wide text-md px-8 py-4 no-underline rounded-sm shadow uppercase",
                          styles.button.default
                        )}
                        type="submit"
                      >
                        {submitButtonText}
                      </button>
                    </div>
                  </div>
                  <div className="w-full mt-2">
                    <button
                      className="w-full bg-transparent text-grey-darkest text-sm underline"
                      onClick={(e) => {
                        e.preventDefault();
                        closeModal();
                      }}
                    >
                      NO I DON'T WANT DISCOUNTS
                    </button>
                  </div>
                </form>
              </div>
            ) : (
              <div
                className="h-full flex flex-col items-center justify-center text-center pb-4 px-6"
                style={{ minHeight: "80px" }}
              >
                <Logo />
                <div
                  className="flex-col wp-content primary-color-h2 mt-12"
                  dangerouslySetInnerHTML={{ __html: afterSubmitBody }}
                />
              </div>
            )}
          </div>
        </div>
      </Modal>
    );
  }

  return <div>{popupOrientation}</div>;
};
export default PromoModal;
