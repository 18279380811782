import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "@reach/router";
import CartContext from "../../context/cart-context";

const PromoBanner = () => {
  const [column1Text, setColumn1Text] = useState("");
  const [column2Text, setColumn2Text] = useState("");
  const [column3Text, setColumn3Text] = useState("");
  const [couponCode, setCouponCode] = useState("");
  const location = useLocation();
  const { setDiscountCode } = useContext(CartContext);

  useEffect(() => {
    async function checkForMarketingBanner() {
      try {
        const search = location.search ? location.search.substring(1) : "";
        const response = await axios.get(
          `${process.env.DATA_API}/wp-json/${
            process.env.COMPANY_NAME_SLUG
          }/v1/marketingBanners/?query_string=${encodeURIComponent(search)}`
        );

        if (response.status === 200) {
          if (response.data.length) {
            setColumn1Text(response.data[0].column_1_text);
            setColumn2Text(response.data[0].column_2_text);
            setColumn3Text(response.data[0].column_3_text);
            setCouponCode(response.data[0].coupon_code);
          }
        } else {
          console.error(response);
        }
      } catch (error) {
        console.error(error);
      }
    }
    checkForMarketingBanner();
  }, [location.search]);

  useEffect(() => {
    if (couponCode) {
      setDiscountCode(couponCode, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponCode]);

  return (
    <div
      className={`${!column1Text && !column2Text ? "hidden" : ""} bg-${
        process.env.THEME_COLOR_PRIMARY
      }-darker text-white z-10 relative`}
    >
      <div className="py-3 px-12 lg:flex flex-wrap justify-center items-center text-center">
        {column1Text && (
          <h2 className={`text-base font-medium`}>{column1Text}</h2>
        )}
        {column2Text && (
          <h3
            className={`my-1 lg:my-0 text-base font-semibold lg:ml-8 xl:ml-16 tracking-wide`}
          >
            {column2Text}
          </h3>
        )}
        {column3Text && <p className="lg:ml-8 xl:ml-16">{column3Text}</p>}
      </div>
    </div>
  );
};
export default PromoBanner;
