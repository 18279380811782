import { useState, useEffect } from "react";
const isWindowAvailable = typeof window !== "undefined";

const getPosition = () => (isWindowAvailable ? window.pageYOffset : undefined);

const useWindowScrollPosition = () => {
  const [scrollPosition, setScrollPosition] = useState(getPosition());

  useEffect(() => {
    if (!isWindowAvailable) {
      return false;
    }
    var throttle;
    const handleScroll = () => {
      if (throttle) {
        clearTimeout(throttle);
      }
      throttle = setTimeout(() => {
        setScrollPosition(getPosition());
      }, 300);
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return scrollPosition;
};

export default useWindowScrollPosition;
