/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import React from "react";
import Layout from "./src/components/layout";
import { CartProvider } from "./src/context/cart-context";

export const wrapRootElement = ({ element }) => (
  <CartProvider>{element}</CartProvider>
);

export const wrapPageElement = ({ element, props }) => {
  return <Layout {...props}>{element}</Layout>;
};

export const onRouteUpdate = () => {
  setTimeout(() => {
    if (typeof window !== "undefined" && typeof window.__ctm !== "undefined") {
      window.__ctm?.main?.runNow();
    }
  }, 1000);
  setTimeout(() => {
    if (typeof window !== "undefined" && typeof window.__ctm !== "undefined") {
      window.__ctm?.main?.runNow();
    }
  }, 3000);
};

export const onInitialClientRender = () => {
  const timestamp = Math.floor(new Date().getTime() / 1000);

  if (typeof sessionStorage !== "undefined") {
    if (!sessionStorage.getItem("bpd-first-page")) {
      sessionStorage.setItem("bpd-first-page", window.location.href);
    }
    if (!sessionStorage.getItem("bpd-referrer")) {
      sessionStorage.setItem("bpd-referrer", document.referrer || "?");
    }
  }

  if (typeof localStorage !== "undefined") {
    const urlParams = new URLSearchParams(window.location.search);

    //check if marketingCampaignInfo in localStorage and if there are urlParams
    if (localStorage.getItem("marketingCampaignInfo") && urlParams.toString()) {
      let marketingCampaign = JSON.parse(
        localStorage.getItem("marketingCampaignInfo")
      );
      //we don't want to push duplicate params to the object if they already exist in the object, which would happen if they refresh the page
      if (!Object.values(marketingCampaign).includes(urlParams.toString())) {
        marketingCampaign[timestamp] = urlParams.toString();
        localStorage.setItem(
          "marketingCampaignInfo",
          JSON.stringify(marketingCampaign)
        );
      }
    } else if (urlParams.toString()) {
      localStorage.setItem(
        "marketingCampaignInfo",
        JSON.stringify({ [`${timestamp}`]: urlParams.toString() })
      );
    }

    if (urlParams.has("referralId")) {
      sessionStorage.setItem("referralId", urlParams.get("referralId"));
    }

    if (
      urlParams.has("utm_source") &&
      urlParams.get("utm_source") === process.env.INTERNAL_UTM_SOURCE
    ) {
      localStorage.setItem("is-internal-sales", true);
    }
    if (urlParams.has("gclid")) {
      localStorage.setItem("bpd-gclid", urlParams.get("gclid"));
    }
    if (urlParams.has("twclid")) {
      localStorage.setItem("bpd-twclid", urlParams.get("twclid"));
    }
    if (urlParams.has("fbclid")) {
      localStorage.setItem("bpd-fbclid", urlParams.get("fbclid"));
    }
    if (urlParams.has("utm_source")) {
      localStorage.setItem("bpd-utm_source", urlParams.get("utm_source"));
    }
    if (urlParams.has("utm_medium")) {
      localStorage.setItem("bpd-utm_medium", urlParams.get("utm_medium"));
    }
    if (urlParams.has("utm_campaign")) {
      localStorage.setItem("bpd-utm_campaign", urlParams.get("utm_campaign"));
    }
    if (urlParams.has("msclkid")) {
      localStorage.setItem("bpd-msclkid", urlParams.get("msclkid"));
    }
  }
};
