import axios from "axios";
import { endOfToday } from "date-fns";
import { graphql, useStaticQuery } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { extendedZipCodes } from "../data/zip-codes";
import { formatPrice } from "../utilities/price";
import { usePostHog } from "posthog-js/react";

const defaultState = {
  checkoutInfo: {
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
    shippingZip: "",
    whichWells: "",
    whichWellsNotes: "",
    shippingAddress: "",
    shippingAddress2: "",
    shippingCity: "",
    shippingNotes: "",
    billingFirstName: "",
    billingLastName: "",
    billingAddress: "",
    billingAddress2: "",
    billingCity: "",
    billingState: process.env.BILLING_STATE,
    billingZip: "",
    billingNotes: "",
    useCardForRemainder: "1",
    callInfo: null,
  },
  contents: [],
  totals: {
    countItems: 0,
    subtotal: 0,
    tax: 0,
    total: 0,
    dueNow: 0,
    dueAtInstall: 0,
    extendedArea: 0,
    discount: 0,
    countOfCoversInCart: 0,
  },
  discounts: {
    amount: 0,
    percent: 0,
  },
  discount: {},
  onProductView: () => {},
  onStartCheckout: () => {},
  requiresExtendedServiceFee: false,
  cartDrawerOpen: false,
  recommendedProducts: () => {
    return [];
  },
  homePageProducts: () => {
    return [];
  },
  accessoryProducts: () => {
    return [];
  },
  addItemToCart: () => {},
  addBundleToCart: () => {},
  checkIfCartHasItemByUrl: () => {},
  removeItemFromCart: () => {},
  removeBundleFromCart: () => {},
  resetCart: () => {},
  setItemQuantity: () => {},
  setBundleQuantity: () => {},
  setDiscountCode: () => {},
  setDiscount: () => {},
  getItemDiscount: () => {},
  setReferenceOrder: () => {},
  getReferenceOrder: () => {},
  setCartRedirect: () => {},
  getCartRedirect: () => {},
  setOrderRequiresNoDeposit: () => {},
  getOrderRequiresNoDeposit: () => {},
  setCartDrawerOpenStatus: () => {},
  setCheckoutInfo: () => {},
};

const defaultDiscount = {
  amount: 0,
  percent: 0,
  expiration: new Date(),
};

const CartContext = React.createContext(defaultState);

const CartProvider = ({ children }) => {
  const posthog = usePostHog();
  const [contents, setContents] = useState([]);
  const [checkoutInfo, setCheckoutInfo] = useState({});
  const [totals, setTotals] = useState({});
  const [discounts, setDiscounts] = useState({});
  const [lastEdited, setLastEdited] = useState(null);
  const [requiresExtendedServiceFee, setRequiresExtendedServiceFee] =
    useState(false);
  const [cartDrawerOpen, setCartDrawerOpen] = useState(false);

  // save localStorage values
  useEffect(() => {
    if (!Object.values(checkoutInfo).length && !contents.length) {
      return;
    }

    localStorage.setItem(
      "cartValues",
      JSON.stringify(
        {
          checkoutInfo,
          contents,
        },
        null,
        2
      )
    );
  }, [checkoutInfo, contents]);

  // load cart values from localStorage
  useEffect(() => {
    if (typeof window !== `undefined` && localStorage) {
      const cartValues = localStorage.getItem("cartValues")
        ? JSON.parse(localStorage.getItem("cartValues"))
        : {
            checkoutInfo: {},
            contents: [],
            lastEdited: new Date(),
          };

      cartValues.contents.sort((a, b) => {
        if (a.basePrice > b.basePrice) {
          return -1;
        }
        if (a.basePrice < b.basePrice) {
          return 1;
        }
        return 0;
      });

      setContents(cartValues.contents);
      setCheckoutInfo(cartValues.checkoutInfo);
      setLastEdited(cartValues.lastEdited);
    }
  }, []);

  useEffect(() => {
    if (checkoutInfo?.shippingZip) {
      const zip = parseInt(checkoutInfo?.shippingZip);
      if (extendedZipCodes.indexOf(zip) >= 0) {
        setRequiresExtendedServiceFee(true);
      } else {
        setRequiresExtendedServiceFee(false);
      }
    } else {
      setRequiresExtendedServiceFee(false);
    }
  }, [checkoutInfo]);

  useEffect(() => {
    if (!contents) {
      return;
    }

    if (!contents.find((item) => item.discount)) {
      return setDiscounts(defaultDiscount);
    }

    contents.map((item) => {
      if (item.discount) {
        setDiscounts({
          name: `$${item.discount}`,
          code: item.id,
          cart_or_item_discount: "item",
          percent_or_amount_off: "amount",
          amount: item.discount,
        });
      }
    });
  }, [contents]);

  // calculate totals
  useEffect(() => {
    const cartBreakdown = getCartBreakdown();
    const getCartDiscount = (discountableProducts) => {
      if (discountableProducts.length === 0) {
        return 0;
      }

      if (
        discounts.percent_or_amount_off === "percent" &&
        discounts.percent > 0
      ) {
        return discounts.percent
          ? discountableProducts.reduce((accumulator, currentValue) => {
              let price = currentValue.salePrice
                ? currentValue.salePrice
                : currentValue.basePrice;

              const itemDiscount =
                +currentValue.quantity * +price * (+discounts.percent / 100);

              return +accumulator + itemDiscount;
            }, 0)
          : discounts.amount;
      }

      if (
        discounts.percent_or_amount_off === "amount" &&
        discounts.amount > 0
      ) {
        if (discounts.cart_or_item_discount === "cart") {
          return discounts.amount;
        }

        const quantityOfDiscountableProducts = discountableProducts.reduce(
          (accumulator, currentValue) => {
            return +currentValue.quantity + accumulator;
          },
          0
        );

        const discountTotal =
          quantityOfDiscountableProducts > discounts.limit
            ? discounts.limit
            : quantityOfDiscountableProducts;

        return discountTotal * discounts.amount;
      }
    };

    if (contents.length) {
      const countItems = contents.reduce((accumulator, currentValue) => {
        return +accumulator + +currentValue.quantity;
      }, 0);
      let subtotal = contents.reduce((accumulator, currentValue) => {
        let price = currentValue.salePrice
          ? currentValue.salePrice
          : currentValue.basePrice;
        return +accumulator + +price * +currentValue.quantity;
      }, 0);
      let extendedArea = requiresExtendedServiceFee
        ? +process.env.EXTENDED_AREA_FEE
        : 0;

      const taxableProductsTotal = contents.reduce(
        (accumulator, currentValue) => {
          if (
            typeof currentValue.taxable === "undefined" ||
            !currentValue.taxable
          ) {
            return +accumulator;
          }
          let price = currentValue.salePrice
            ? currentValue.salePrice
            : currentValue.basePrice;
          return +accumulator + +price * +currentValue.quantity;
        },
        0
      );

      const discountableProducts = contents.filter((product) => {
        return product.id === discounts.code;
      });

      const cartDiscount = getCartDiscount(discountableProducts);

      let tax =
        +((taxableProductsTotal - cartDiscount) * process.env.TAX_RATE).toFixed(
          2
        ) || 0;

      let total = subtotal - cartDiscount + extendedArea + tax;

      let dueNow =
        total && (cartBreakdown.covers || cartBreakdown.liners) ? 100 : total;
      let dueLater = Math.max(0, total - dueNow);

      let dueAtMeasure = cartBreakdown.covers
        ? Math.round((dueLater * 100) / 2) / 100
        : 0;
      let dueAtInstall =
        Math.round((total - dueNow - dueAtMeasure) * 100) / 100;

      if (getOrderRequiresNoDeposit()) {
        dueNow = 0;
        dueAtMeasure = 0;
        dueAtInstall = total;
      }

      if (!cartBreakdown.covers && !cartBreakdown.liners) {
        dueNow = 0;
        dueAtMeasure = 0;
        dueAtInstall = 0;
      }

      setTotals({
        countItems,
        subtotal: formatPrice(subtotal),
        tax: formatPrice(tax),
        total: formatPrice(Math.round((total + Number.EPSILON) * 100) / 100),
        dueNow: formatPrice(dueNow),
        dueAtInstall: formatPrice(dueAtInstall),
        dueAtMeasure: formatPrice(dueAtMeasure),
        extendedArea: formatPrice(extendedArea),
        discount: formatPrice(cartDiscount || 0),
        countOfCoversInCart: cartBreakdown.covers,
      });
    } else {
      setTotals({
        countItems: 0,
        subtotal: 0,
        tax: 0,
        total: 0,
        dueNow: 0,
        dueAtInstall: 0,
        dueAtMeasure: 0,
        extendedArea: 0,
        discount: 0,
        countOfCoversInCart: 0,
      });
    }
  }, [contents, checkoutInfo, discounts, requiresExtendedServiceFee]);

  const accessoryProductsQuery = useStaticQuery(graphql`
    query GetAccessoryProductQuery {
      allWpProduct {
        edges {
          node {
            id
            title
            content
            slug
            status
            featuredImage {
              node {
                title
                localFile {
                  publicURL

                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
            product {
              basePrice
              salePrice
              sku
              taxable
              category
              cartCalloutText
              type
              description
              variations {
                variationName
                variationBasePrice
                variationSalePrice
                variationAttributes {
                  variationAttributeName
                  variationAttributeValue
                }
                variationSku
                variationSkus {
                  variationSkusSku
                }
                variationDescription
                variationShortDescription
                variationFeaturedImage {
                  title
                  localFile {
                    publicURL
                  }
                }
              }
            }
            seo {
              title
              metaDesc
              canonical
            }
          }
        }
      }
    }
  `);

  useEffect(() => {
    if (lastEdited) {
      // if the cart was last edited over 12 hours ago clear it
      if (lastEdited <= +new Date() - 43200000) {
        resetCart();
      }
    }
  }, [lastEdited]);

  useEffect(() => {
    if (sessionStorage.getItem("discounts")) {
      setDiscount(JSON.parse(sessionStorage.getItem("discounts")));
      setDiscounts(JSON.parse(sessionStorage.getItem("discounts")));
    }
  }, []);

  const recommendedProducts = () => {
    return accessoryProducts()
      .filter((product) => {
        return product.node.slug !== "polycarbonate-topper";
      })
      .filter((product) => {
        if (
          !product.node.product.basePrice ||
          checkIfCartHasItemByUrl(`/${product.node.slug}/`)
        ) {
          return false;
        }
        return true;
      });
  };

  const accessoryProducts = () => {
    return accessoryProductsQuery.allWpProduct.edges
      .filter((product) => {
        return product.node.product.category.includes("accessories");
      })
      .sort((productA, productB) =>
        +productA.node.product.basePrice < +productB.node.product.basePrice
          ? 1
          : -1
      );
  };

  const getCartBreakdown = () => {
    const covers = contents
      .filter((product) => {
        return product.category.includes("covers");
      })
      .reduce((n, { quantity }) => n + quantity, 0);

    const liners = contents
      .filter((product) => {
        return product.category.includes("liners");
      })
      .reduce((n, { quantity }) => n + quantity, 0);

    const accessories = contents
      .filter((product) => {
        return product.category.includes("accessories");
      })
      .reduce((n, { quantity }) => n + quantity, 0);

    return { covers, liners, accessories };
  };

  const homePageSkuOptions = [
    "window-well-cover-steel-black",
    "window-well-cover-polycarbonate",
    "window-well-cover-steel-black-poly-cover",
  ];

  const homePageProducts = () => {
    return accessoryProductsQuery.allWpProduct.edges
      .filter((product) => {
        return product.node.product.sku === "window-well-cover";
      })
      .map((product) =>
        product.node.product.variations.filter((variation) => {
          return homePageSkuOptions.includes(variation.variationSku);
        })
      )[0];
  };

  const resetCart = () => {
    setLastEdited(+new Date());
    setContents([]);
    setCheckoutInfo({});
    setDiscounts(defaultDiscount);

    localStorage.setItem(
      "cartValues",
      JSON.stringify(
        {
          checkoutInfo: {},
          contents: [],
        },
        null,
        2
      )
    );
  };

  const setItemQuantity = (id, quantity) => {
    if (!quantity) {
      quantity = 1;
    }

    const itemInCart = contents.filter((value) => {
      if (value.id === id) {
        return value;
      }
      return false;
    });
    if (itemInCart.length) {
      setContents(
        contents.map((value) => {
          if (value.id === id) {
            value.quantity = +quantity;
          }
          return value;
        })
      );
    }
  };

  const setBundleQuantity = (bundleId, quantity) => {
    if (!quantity) {
      quantity = 1;
    }

    setContents(
      contents.map((product) => {
        if (product.bundleId === bundleId) {
          product.quantity = +quantity;
        }
        return product;
      })
    );
  };

  const addItemToCart = (item) => {
    if (item.bundleId) {
      return setContents((prevContents) => [...prevContents, item]);
    }
    const itemInCart = contents.filter((product) => {
      if (product.id === item.id && !product.bundleId) {
        return product;
      }
      return false;
    });
    if (itemInCart.length) {
      setContents((prevContents) =>
        prevContents.map((product) => {
          if (product.id === item.id) {
            product.quantity = +product.quantity + +item.quantity;
          }
          return product;
        })
      );
    } else {
      setContents((prevContents) => [...prevContents, item]);
    }
  };

  const addBundleToCart = (bundle) => {
    setContents((prevContents) => [...prevContents, bundle]);
  };

  const checkIfCartHasItemByUrl = (url = "") => {
    if (!contents.length) {
      return false;
    }
    return contents.filter((value) => {
      return value.url === url;
    }).length;
  };

  const removeItemFromCart = (id) => {
    const newContents = contents.filter((value) => {
      return value.id !== id;
    });
    setContents(newContents);
  };

  const removeBundleFromCart = (id) => {
    const newContents = contents.filter((product) => {
      return product.bundleId !== id;
    });
    setContents(newContents);
  };

  const setDiscountCode = async (code, notify = true) => {
    if (code === "") {
      setDiscounts(defaultDiscount);
      return;
    }

    if (code === discounts?.code) {
      return;
    }

    const response = await axios.get(
      `${process.env.DATA_API}/wp-json/${process.env.COMPANY_NAME_SLUG}/v1/discounts/?code=${code}`
    );

    if (response.status === 200) {
      if (response.data.length) {
        if (new Date() > new Date(response.data[0].expiration)) {
          if (notify) {
            toast.error("That offer has expired");
          }
        } else {
          setDiscount({
            name: response.data[0].name,
            code: response.data[0].code,
            cart_or_item_discount: response.data[0].cart_or_item_discount,
            percent_or_amount_off: response.data[0].percent_or_amount_off,
            amount: response.data[0].amount_off,
            percent: response.data[0].percent_off,
            categories: response.data[0].categories,
            expiration: new Date(response.data[0].expiration),
          });
          setCheckoutInfo((c) => ({
            ...c,
            discountCode: response.data[0].code,
          }));
          if (notify) {
            toast.success("Discount applied");
          }
          return response.data[0];
        }
      } else {
        if (notify) {
          toast.info("The discount code you entered is not valid");
        }
      }
    } else {
      console.error(response);
    }
    return [];
  };

  const setDiscount = (discount) => {
    if (discount === "allforthetest") {
      const discountData = {
        name: "All For The Test",
        code: "All For The Test",
        cart_or_item_discount: "cart",
        percent_or_amount_off: "percent",
        amount: 0,
        percent: 100,
        expiration: endOfToday(),
        categories: ["accessories", "covers"],
      };
      setDiscounts({
        discounts: discountData,
      });
      sessionStorage.setItem("discounts", JSON.stringify(discountData));
      return;
    }

    if (discount.amount || discount.percent) {
      const discountData = {
        name: discount.name ? discount.name : "",
        code: discount.code ? discount.code : "",
        amount:
          discount.percent_or_amount_off === "amount" && discount.amount
            ? +discount.amount
            : 0,
        percent:
          discount.percent_or_amount_off === "percent" && discount.percent
            ? +discount.percent
            : 0,
        expiration: discount.expiration ? +discount.expiration : 0,
        categories: discount.categories ? discount.categories : [],
        cart_or_item_discount: discount.cart_or_item_discount
          ? discount.cart_or_item_discount
          : "cart",
        percent_or_amount_off: discount.percent_or_amount_off
          ? discount.percent_or_amount_off
          : "percent",
        limit: discount.limit ? discount.limit : undefined,
      };
      setDiscounts(discountData);
      sessionStorage.setItem("discounts", JSON.stringify(discountData));
      return;
    } else {
      setDiscounts(defaultDiscount);
    }
  };

  const getItemDiscount = (item) => {
    // if there is no discount return item price
    if (!discounts.percent && !discounts.amount) {
      return item.salePrice ? item.salePrice : item.basePrice;
    }

    // check applicable categories
    if (
      discounts.categories.length &&
      !discounts.categories.filter((value) => item.category.includes(value))
        .length
    ) {
      return item.salePrice ? item.salePrice : item.basePrice;
    }

    if (discounts.percent_or_amount_off === "percent" && discounts.percent) {
      return item.salePrice
        ? item.salePrice * ((100 - discounts.percent) / 100)
        : item.basePrice * ((100 - discounts.percent) / 100);
    }

    if (
      discounts.percent_or_amount_off === "amount" &&
      discounts.cart_or_item_discount === "item" &&
      discounts.amount
    ) {
      const discountedPrice = item.salePrice
        ? item.salePrice - discounts.amount
        : item.basePrice - discounts.amount;
      // no negative prices
      return discountedPrice > 0 ? discountedPrice : 0;
    }

    return item.salePrice ? item.salePrice : item.basePrice;
  };

  const setReferenceOrder = (order) => {
    return sessionStorage.setItem("reference_order", order);
  };

  const getReferenceOrder = () => {
    return sessionStorage.getItem("reference_order")
      ? sessionStorage.getItem("reference_order")
      : "";
  };

  const getCartRedirect = () => {
    return sessionStorage.getItem("cart_redirect")
      ? sessionStorage.getItem("cart_redirect")
      : "";
  };

  const setCartRedirect = (path = "") => {
    return sessionStorage.setItem("cart_redirect", path);
  };

  const setOrderRequiresNoDeposit = () => {
    return sessionStorage.setItem(
      "order_requires_no_deposit",
      "0c7adabb60059d12d29c6ecdfac43c40"
    );
  };

  const getOrderRequiresNoDeposit = () => {
    return sessionStorage.getItem("order_requires_no_deposit");
  };

  const setCartDrawerOpenStatus = (status) => {
    return setCartDrawerOpen(status);
  };

  const onStartCheckout = (step = 1) => {
    if (typeof window === "undefined") return;

    const eventName =
      step === 1
        ? "begin_checkout"
        : step === 2
        ? "add_shipping_info"
        : "add_payment_info";

    const cartContents = contents.map((product, index) => {
      return {
        item_id: product.id,
        item_name: product.title,
        index,
        item_brand: process.env.COMPANY_NAME,
        item_category: Array.isArray(product.category)
          ? product.category[0]
          : product.category,
        price: product.salePrice ? +product.salePrice : +product.basePrice,
        quantity: product.quantity,
      };
    });

    const eventData = {
      currency: "USD",
      value: cartContents.reduce((accumulator, currentValue) => {
        return accumulator + currentValue.price * currentValue.quantity;
      }, 0),
      items: cartContents,
    };

    console.info("onStartCheckout", eventData);

    try {
      window.gtag("event", eventName, eventData);
    } catch (error) {
      console.error(error);
    }

    try {
      posthog.capture(eventName, eventData);
    } catch (error) {
      console.error(error);
    }
  };

  const onProductView = (product) => {
    if (typeof window === "undefined" || !window.gtag) return;

    const eventData = {
      currency: "USD",
      value: product.salePrice ? product.salePrice : product.basePrice,
      items: [
        {
          item_id: product.sku,
          item_name: product.title,
          item_brand: process.env.COMPANY_NAME,
          item_category: Array.isArray(product.category)
            ? product.category.join(", ")
            : product.category,
          value: product.salePrice ? product.salePrice : product.basePrice,
          quantity: 1,
        },
      ],
    };

    console.info("onProductView", eventData);

    try {
      window.gtag("event", "view_item", eventData);
    } catch (error) {
      console.error(error);
    }

    try {
      posthog.capture("view_item", eventData);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <CartContext.Provider
      value={{
        accessoryProducts: accessoryProducts,
        addBundleToCart: addBundleToCart,
        addItemToCart: addItemToCart,
        cartDrawerOpen: cartDrawerOpen,
        checkIfCartHasItemByUrl: checkIfCartHasItemByUrl,
        checkoutInfo,
        contents,
        discounts,
        getCartRedirect: getCartRedirect,
        getItemDiscount: getItemDiscount,
        getOrderRequiresNoDeposit: getOrderRequiresNoDeposit,
        getReferenceOrder: getReferenceOrder,
        homePageProducts: homePageProducts,
        onProductView: onProductView,
        onStartCheckout: onStartCheckout,
        recommendedProducts: recommendedProducts,
        removeBundleFromCart: removeBundleFromCart,
        removeItemFromCart: removeItemFromCart,
        requiresExtendedServiceFee: requiresExtendedServiceFee,
        resetCart: resetCart,
        setBundleQuantity: setBundleQuantity,
        setCartDrawerOpenStatus: setCartDrawerOpenStatus,
        setCartRedirect: setCartRedirect,
        setCheckoutInfo: setCheckoutInfo,
        setDiscount: setDiscount,
        setDiscountCode: setDiscountCode,
        setItemQuantity: setItemQuantity,
        setOrderRequiresNoDeposit: setOrderRequiresNoDeposit,
        setReferenceOrder: setReferenceOrder,
        totals,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

CartContext.propTypes = {
  contents: PropTypes.array,
  checkoutInfo: PropTypes.object,
};
export default CartContext;

export { CartProvider };
