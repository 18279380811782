import React from "react";
import { StaticImage } from "gatsby-plugin-image";

export const HeaderImage = () => {
  return (
    <StaticImage
      alt={process.env.COMPANY_NAME_SHORT}
      className="m-auto"
      height={201 / 4}
      src="../../images/logo-black.png"
      width={702 / 4}
    />
  );
};
