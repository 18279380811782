import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const LogoGrey = () => (
  <StaticQuery
    query={graphql`
      {
        placeholderImage: file(relativePath: { eq: "logo-grey.png" }) {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <GatsbyImage
        alt="Gray Logo"
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        style={{ width: "300px" }}
      />
    )}
  />
);
export default LogoGrey;
