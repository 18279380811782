import React from "react";
import { StaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

const Logo = (props) => (
  <StaticQuery
    query={graphql`
      {
        placeholderImage: file(relativePath: { eq: "logo.png" }) {
          childImageSharp {
            gatsbyImageData(width: 300, layout: CONSTRAINED)
          }
        }
      }
    `}
    render={(data) => (
      <GatsbyImage
        alt="Logo"
        image={data.placeholderImage.childImageSharp.gatsbyImageData}
        className={props.className}
        style={{ width: props.width ? props.width : "220px" }}
      />
    )}
  />
);
export default Logo;
