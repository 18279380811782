import classNames from "classnames";

export const styles = {
  h2: classNames(
    "text-grey-darkest text-3xl font-bold leading-tighter",
    "lg:text-4xl"
  ),
  h3: classNames(
    "text-grey-darkest text-2xl font-bold leading-tighter",
    "lg:text-3xl"
  ),
  h4: classNames(
    "text-grey-darkest text-xl font-bold leading-tighter",
    "lg:text-2xl"
  ),
  h5: classNames(
    "text-grey-darkest text-lg font-bold leading-tighter",
    "lg:text-xl"
  ),
  button: {
    default: classNames("text-black bg-yellow hover:bg-yellow-dark"),
  },
};
